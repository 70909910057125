import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Table from './Table'
import types from '../../Constants/Extension'
import BreadCrumb from '../../Components/Common/Breadcrumb'
import FilePreview from '../../Components/FilePreview'
import { useDispatch } from 'react-redux'
import {
  handleIsValidLink,
  listCloud,
  setListAllResourceDataError,
  setPageCountResourceList,
  setParentFolderName,
  validateShareLink,
} from '../../Redux/Home/actions'
import debounce from 'lodash.debounce'
import InfiniteScroll from 'react-infinite-scroll-component'

import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

import { otherItemsDownloadOnMessage } from '../../Constants/Messages'
import { hiddenFiles, previewTypes } from '../../Constants/types'

const Home = ({
  parentId,
  setParentId,
  isSelectedFolder,
  setIsSelectedFolder,
  home,
  downloadLinks,
  setDownloadLinks,
  downloadFolderName,
  setDownloadFolderName,
  fileType,
  setFileType,
  superParentId,
  setSuperParentId
}) => {
  const dispatch = useDispatch()
  const {
    listCloudData,
    listCloudDataCount,
    singleCloudData,
    loading,
    pageNoResource,
    parentFolderName
  } = home
  const location = useLocation()

  //UseStates
  const [parentShareLink] = useState({
    link: location.search.split('=')[1],
    isDownloadPage: location.search.split("/")[1],
    id: parseInt(location.pathname.split('/')[1]),
  })

  const [dataLoading, setDataLoading] = useState(false)
  const [preview, setPreview] = useState({
    id: 0,
    url: '',
    type: '',
    status: false,
  })
  const [index, setIndex] = useState(0)
  const [searchedValue, setSearchedValue] = useState('')
  const [nameSort, setNameSort] = useState('DESC')
  const [isCheckedAll, setIsCheckedAll] = useState(false)
  const [selectedId, setSelectedId] = useState([])
  const [isNoDataFound, setIsNoDataFound] = useState(false)
  const [isValidLink, setIsValidLink] = useState(true)
  const [isDownload, setIsDownload] = useState(false)
  const [isNameSort, setIsNameSort] = useState(false)
  const [isFirstPage, setIsFirstPage] = useState(true)
  const [isMultiDownload, setIsMultiDownload] = useState(false)
  const [isPagination, setIsPagination] = useState(false)
  // const downloadFile = useCallback(({ id, path, title }, multi = false) => {
  //   if (!multi) {
  //     setDownloadData({ id: id, isDownload: true })
  //   }
  //   axios({
  //     url: path,
  //     method: 'GET',
  //     responseType: 'blob',
  //     onDownloadProgress: (progressEvent) => {
  //       console.log(Math.round((progressEvent.loaded * 100) / progressEvent.total))
  //     }
  //   }).then((response) => {
  //     const url = window.URL.createObjectURL(new Blob([response.data]))
  //     const link = document.createElement('a')
  //     link.href = url
  //     link.setAttribute('download', title)
  //     document.body.appendChild(link)
  //     link.click()
  //     setDownloadData({ id: null, isDownload: false })
  //     toast.success("Download is successfully")
  //   })
  // }, [])

  const downloadImage = useCallback((file) => {
    // let downloadLink = null
    const { id } = file
    // if (type === 0) {
    let downloadLink = `${process.env.REACT_APP_API_CLOUD}api/v1/downloadzip?files=${id}`;
    const link = document.createElement('a')
    link.href = downloadLink
    document.body.appendChild(link)
    link.click()
    // } else {
    //   if (downloadData.isDownload) {
    // } else {
    //   downloadFile(file)
    // }
    // }

    setIsDownload(false)
    setIsCheckedAll(false)
    setIsMultiDownload(false)
    setSelectedId([])
    setDownloadLinks([])
    setDownloadFolderName([])

  }, [setDownloadFolderName, setDownloadLinks])

  useEffect(() => {
    if (!parentShareLink.link?.split("/")[0]) {
      setIsValidLink(false)
    } else {
      setDataLoading(true)
      dispatch(
        validateShareLink(
          {
            shared_link: parentShareLink.link?.split("/")[0],
          },
          (status) => {
            const { data } = status?.data
            if (status?.message === 'Invalid Link.') {
              setIsValidLink(false)
              dispatch(handleIsValidLink(false))
            }
            if (!!data && !!data?.resource?.extension) {
              dispatch(setParentFolderName(data?.resource?.path.split("/")[data?.resource?.path.split("/").length - 2]))
            } else {
              dispatch(setParentFolderName(data?.resource?.path.split("/")[data?.resource?.path.split("/").length - 1]))
            }
            if (status?.data?.data?.resource?.type === 0) {
              setSuperParentId(data?.resource.id)
              setParentId(data?.resource.id)
              setFileType(data?.resource?.type)
            }
            if (status.data.data.resource.type === 0) {
              setIsFirstPage(false)
            } else {
              setDataLoading(false)
            }
            if (parentShareLink?.isDownloadPage === "download" && data?.resource?.type === 1) {
              downloadImage(data?.resource)
            }
          },
        ),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, parentShareLink])




  useEffect(() => {
    setIsNoDataFound(false)
    if (parentId && fileType === 0) {

      setIsValidLink(true)
      if (pageNoResource > 1) {
        setIsPagination(true)
      }

      singleCloudData === 0 &&
        dispatch(
          listCloud(
            {
              page_no: pageNoResource,
              page_record: 25,
              parent_id: parentId,
              search: searchedValue.trim(),
              sortOrder: nameSort,
              isPagination: pageNoResource !== 1 ? true : false,
            },
            (status) => {
              if (status) {
                setDataLoading(false)
                if (!status.data.count) {
                  setIsNoDataFound(true)
                }

                setIsPagination(false)

                if (parentShareLink.isDownloadPage === "download") {
                  let downloadKey = (location.search.split('=')[1]).split("/")[0]
                  var a = document.createElement('a');
                  a.href = `${process.env.REACT_APP_API_CLOUD}api/v1/downloadzip/${downloadKey}`;
                  document.body.appendChild(a);
                  a.click();
                  a.download = true;
                  document.body.removeChild(a);
                }

              }
            },
          ),
        )
    }

  }, [dispatch, singleCloudData, pageNoResource, parentId, parentShareLink, searchedValue, nameSort, fileType, location.search])

  //Handle Breadcrumb Item by selected folder
  const handleItemsLength = (handleItemsLength) => {
    if (handleItemsLength || handleItemsLength === 0) {
      if (!isDownload && !isMultiDownload) {
        setSelectedId([])
        setIsCheckedAll(false)
      }
      let isItemsLength = isSelectedFolder.length - (handleItemsLength - 1)
      isSelectedFolder.splice(handleItemsLength + 1, isItemsLength)
    }
  }

  //Searching by Name
  const handleSearch = debounce((e) => {
    dispatch(setListAllResourceDataError())
    setSearchedValue(e.target.value)
    dispatch(setPageCountResourceList(1))
  }, 1000)

  //Infinite scroll for Resource List
  const next = () => {
    dispatch(setPageCountResourceList(pageNoResource + 1))
  }

  //Store On select Parent Id
  const onSelectParentId = (onSelectParentId) => {
    dispatch(setPageCountResourceList(1))

    setParentId(onSelectParentId.id)
    if (
      (onSelectParentId.extension === '' || onSelectParentId.extension === null) &&
      isSelectedFolder.includes(onSelectParentId) === false
    ) {
      setIsSelectedFolder((prev) => [...prev, onSelectParentId])
    }
  }

  //Close Preview
  const handleClose = () => {
    setPreview({ status: false })
    setIndex(0)
    // document.body.style.overflow = 'scroll'
  }

  //handle Set Index
  const handleSelectedFolder = (handleSelectedFolder) => {
    setIndex(handleSelectedFolder)
  }

  //handle Set Preview
  const handleSetPreview = (handleSetPreview) => {
    setPreview(handleSetPreview)
  }

  //handle Sort_Order
  const handleSortName = (handleSortName) => {
    setNameSort(handleSortName)
  }

  //handle bytes
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes'
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }

  const handleSelectAll = (check) => {
    if (!isDownload && !isMultiDownload) {
      if (check && selectedId.length > 0) {
        setSelectedId([])
        setDownloadLinks([])
        setDownloadFolderName([])
        setIsCheckedAll(false)
      } else if (check) {
        listCloudData.map((item) =>
          setSelectedId((prev) => [
            ...prev,
            !selectedId.includes(item.id) && item.id,
          ]),
        )
        listCloudData.map((item) => {
          if (item.type === 1) {
            if (downloadLinks.includes(item.path)) {
              setDownloadLinks(
                downloadLinks.filter((item) =>
                  item === item.path ? null : item,
                ),
              )
            } else {
              setDownloadLinks((prev) => [...prev, item.path])
            }
          } else {
            if (downloadFolderName.includes(item.path)) {
              setDownloadFolderName(
                downloadFolderName.filter((item) =>
                  item === item.path ? null : item,
                ),
              )
            } else {
              setDownloadFolderName((prev) => [...prev, item.path])
            }
          }
          return null
        })
      } else {
        setSelectedId([])
        setDownloadLinks([])
        setDownloadFolderName([])
      }
    }
  }

  const handleCheckOrUncheck = (id, path, title, type) => {
    if (!isDownload && !isMultiDownload) {
      if (selectedId.includes(id)) {
        setSelectedId(selectedId.filter((item) => (item === id ? null : item)))

        setIsCheckedAll(false)
      } else {
        setSelectedId((prev) => [...prev, id])
        if (listCloudData.length === selectedId.length + 1) {
          setIsCheckedAll(true)
        }
      }
      if (type === 1) {
        if (downloadLinks.includes(path)) {
          setDownloadLinks(
            downloadLinks.filter((item) => (item === path ? null : item)),
          )
        } else {
          setDownloadLinks((prev) => [...prev, path])
        }
      } else {
        if (downloadFolderName.includes(path)) {
          setDownloadFolderName(
            downloadFolderName.filter((item) => (item === path ? null : item)),
          )
        } else {
          setDownloadFolderName((prev) => [...prev, path])
        }
      }
    } else {
      toast(otherItemsDownloadOnMessage)
    }
  }

  //Handle Preview
  const previewViewerHandler = (e, row) => {
    if (row.type === 0) {
      onSelectParentId(row)
    }
    if (previewTypes.includes(row.extension)) {
      handleOpen(e, row.id)
      handleSetPreview({
        id: row.id,
        url: row.path,
        type: row.type,
        status: row.extension !== '' && row.extension !== 'zip' ? true : false,
      })
    } else {
      if (row.type !== 0) {
        if (!isDownload && !isMultiDownload) {
          console.log(row);
          downloadImage(row)
        } else {
          toast(otherItemsDownloadOnMessage)
        }
      }
    }

  }

  //Open Preview
  const handleOpen = (e, id) => {
    e.preventDefault()
    listCloudData.forEach((ele, index) => {
      if (ele.id === id) {
        handleSelectedFolder(index)
      }
    })
    handleSetPreview({ status: true })
    // document.body.style.overflow = 'hidden'
  }

  //Sorting By Name
  const handleSortByName = () => {
    if (selectedId.length === 0) {
      //Empty Redux
      dispatch(setListAllResourceDataError())
      //set Page Count
      dispatch(setPageCountResourceList(1))
      setIsNameSort(true)
      if (nameSort === 'ASC') {
        handleSortName('DESC')
      } else {
        handleSortName('ASC')
      }
    }
  }

  const multiDownload = () => {
    let folder = []
    selectedId.map((item) =>
      folder.push(`${process.env.REACT_APP_API_CLOUD}api/v1/downloadzip?files=${item}`)
    )
    download_files(folder)
    // var a = document.createElement("a");
    // a.href = `${process.env.REACT_APP_API_CLOUD}api/v1/downloadzip?files=${selectedId.join(",")}`;
    // a.download = true;
    // a.click();
    // document.body.appendChild(a);
    // document.body.removeChild(a);
    setSelectedId([])
    setIsCheckedAll(false)
  }

  function download_files(files) {

    function download_next(i) {
      if (i >= files.length) {
        return;
      }
      var a = document.createElement("a");
      a.href = files[i];
      a.target = '_parent';
      a.download = true;
      a.click();
      document.body.appendChild(a);
      document.body.removeChild(a);
      setTimeout(function () {
        download_next(i + 1);
      }, 2500);
    }
    download_next(0);
  }
  const hasMore = useMemo(
    () => 25 * pageNoResource < listCloudDataCount,
    [listCloudDataCount, pageNoResource]
  );


  return (
    <div className="body">
      <div className="cloud-container pt-4 pb-4">
        <div className="">
          <div className="card main-card card-company-table p-4 mb-0">
            {parentShareLink.isDownloadPage ? (
              <div className="data-center inner-card">
                <div className='urlDownload'>

                  <div className="main-container">
                    <div className="check-container">
                      <div className="check-background">
                        <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7 25L27.3077 44L58.5 7" stroke="white" strokeWidth="13" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </div>
                      <div className="check-shadow"></div>
                    </div>
                  </div>

                  <h4 className="data-center mt-4">
                    {"Download"}
                  </h4>
                </div>
              </div>
            ) : !isValidLink && !loading ? (
              <div className="inner-card data-center">
                <div className="col-xl-8 col-md-10 col-lg-10 col-12 share-link-main p-5 rounded-3">
                  <i className="fas fa-search share-link-icon data-center"></i>
                  <h3 className="data-center">File not found</h3>
                  <h4 className="data-center">
                    The files could not be found on the server. It might be possible that the files were deleted.
                  </h4>
                </div>
              </div>
            ) : (
              <div>
                <BreadCrumb
                  parentFolderName={parentFolderName}
                  superParentId={superParentId}
                  parentId={parentId}
                  parentShareLink={parentShareLink.id}
                  link={parentId}
                  setParentId={setParentId}
                  handleSearch={handleSearch}
                  isSelectedFolder={isSelectedFolder}
                  handleItemsLength={handleItemsLength}
                  isFirstPage={isFirstPage}
                />

                <div >
                  <InfiniteScroll
                    dataLength={listCloudData?.length}
                    next={next}
                    hasMore={hasMore}
                    loader={
                      isPagination && (
                        <center>
                          <h4 className="loading">
                            <i className="fa fa-spinner fa-spin"></i>
                            <span> Data Loading Please Wait...</span>
                          </h4>
                        </center>
                      )
                    }
                  >

                    <Table
                      dataLoading={dataLoading}
                      types={types}
                      nameSort={nameSort}
                      setIsCheckedAll={setIsCheckedAll}
                      isCheckedAll={isCheckedAll}
                      handleSelectAll={handleSelectAll}
                      formatBytes={formatBytes}
                      selectedId={selectedId}
                      handleCheckOrUncheck={handleCheckOrUncheck}
                      previewViewerHandler={previewViewerHandler}
                      downloadImage={downloadImage}
                      isNoDataFound={isNoDataFound}
                      isDownload={isDownload}
                      setIsNameSort={setIsNameSort}
                      isNameSort={isNameSort}
                      handleSortByName={handleSortByName}
                      multiDownload={multiDownload}
                      isFirstPage={isFirstPage}
                      isMultiDownload={isMultiDownload}
                      home={home}
                      hiddenFiles={hiddenFiles}
                    />

                  </InfiniteScroll>
                </div>
                <div className="gallery-div media-gallery">
                  {preview.status && (
                    <div className="gallery-carousel">
                      <FilePreview
                        handleClose={handleClose}
                        setIndex={setIndex}
                        type={preview.type}
                        data={listCloudData}
                        index={index}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default Home
